@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap');
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
  box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
a{
  text-decoration: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
.mob_button,
.mob-btn-close{
  display: none;
}
.page_wrapp{
  /*background-image: url(./img/app-bg.jpg);*/
  background-size: cover;
  background-position: top center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.wrap_main{
  height: calc(100vh - 110px);
}
.page_wrapp_tranding{
  background-color: #262726;
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
}
.ot_wrapp{
  background: #0D0D0D;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.overlay{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 5.97%, #262726 75.77%);
transform: rotate(-180deg);
}
body{
  font-family: 'Ubuntu', sans-serif;
  font-size: 14px;
  line-height: 1.2;
}
header{
  background: #0D0D0D;
  box-shadow: -10px 0px 28px rgba(0, 0, 0, 0.25);
  max-height: 56px;
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
  z-index: 3;
}
.menu_container{
  position: relative;
}
/*Notification*/
.notification_wrap{
  position: absolute;
  top: 10px;
  left: 50%;
  z-index: 1040;
  transition: top 0.5s linear;
}
.notification_message{
  width: 400px;
  left: -200px;
  position: relative;
  border-radius: 3px;
  padding: 12px 36px;
  border: 1px solid #414244;
}
.notification_message span.executed,
.notification_message span.cancelled,
.notification_message span.added,
.notification_message span.error{
  position: relative;
}
.notification_message span.executed:before,
.notification_message span.cancelled:before,
.notification_message span.added:before,
.notification_message span.error:before{
  content:"";
  top: 1px;
  left: -20px;
  width: 13px;
  height: 13px;
  position: absolute;
}
.notification_message span.executed{
  color: #fff;
}
.notification_message span.executed:before{
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 0.75C3.55375 0.75 0.75 3.55375 0.75 7C0.75 10.4462 3.55375 13.25 7 13.25C10.4462 13.25 13.25 10.4462 13.25 7C13.25 3.55375 10.4462 0.75 7 0.75ZM7 3.3125C7.1607 3.3125 7.31779 3.36015 7.4514 3.44943C7.58502 3.53871 7.68916 3.6656 7.75065 3.81407C7.81215 3.96253 7.82824 4.1259 7.79689 4.28351C7.76554 4.44112 7.68815 4.58589 7.57452 4.69952C7.46089 4.81315 7.31612 4.89054 7.15851 4.92189C7.0009 4.95324 6.83753 4.93715 6.68907 4.87565C6.5406 4.81416 6.41371 4.71002 6.32443 4.5764C6.23515 4.44279 6.1875 4.2857 6.1875 4.125C6.1875 3.90951 6.2731 3.70285 6.42548 3.55048C6.57785 3.3981 6.78451 3.3125 7 3.3125ZM8.5 10.375H5.75C5.61739 10.375 5.49021 10.3223 5.39645 10.2286C5.30268 10.1348 5.25 10.0076 5.25 9.875C5.25 9.74239 5.30268 9.61521 5.39645 9.52145C5.49021 9.42768 5.61739 9.375 5.75 9.375H6.625V6.625H6.125C5.99239 6.625 5.86521 6.57232 5.77145 6.47855C5.67768 6.38479 5.625 6.25761 5.625 6.125C5.625 5.99239 5.67768 5.86521 5.77145 5.77145C5.86521 5.67768 5.99239 5.625 6.125 5.625H7.125C7.25761 5.625 7.38479 5.67768 7.47855 5.77145C7.57232 5.86521 7.625 5.99239 7.625 6.125V9.375H8.5C8.63261 9.375 8.75979 9.42768 8.85355 9.52145C8.94732 9.61521 9 9.74239 9 9.875C9 10.0076 8.94732 10.1348 8.85355 10.2286C8.75979 10.3223 8.63261 10.375 8.5 10.375Z' fill='white'/%3E%3C/svg%3E");
}
.notification_message span.cancelled{
  color: #FC8654;
}
.notification_message span.cancelled:before{
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 0.5C3.41594 0.5 0.5 3.41594 0.5 7C0.5 10.5841 3.41594 13.5 7 13.5C10.5841 13.5 13.5 10.5841 13.5 7C13.5 3.41594 10.5841 0.5 7 0.5ZM7 10.4972C6.87639 10.4972 6.75555 10.4605 6.65277 10.3919C6.54999 10.3232 6.46988 10.2256 6.42258 10.1114C6.37527 9.99716 6.36289 9.87149 6.38701 9.75026C6.41112 9.62902 6.47065 9.51765 6.55806 9.43025C6.64547 9.34284 6.75683 9.28331 6.87807 9.2592C6.99931 9.23508 7.12497 9.24746 7.23918 9.29476C7.35338 9.34207 7.45099 9.42218 7.51967 9.52496C7.58834 9.62774 7.625 9.74857 7.625 9.87219C7.625 10.0379 7.55915 10.1969 7.44194 10.3141C7.32473 10.4313 7.16576 10.4972 7 10.4972ZM7.67875 4.21125L7.49938 8.02375C7.49938 8.15636 7.4467 8.28354 7.35293 8.3773C7.25916 8.47107 7.13198 8.52375 6.99937 8.52375C6.86677 8.52375 6.73959 8.47107 6.64582 8.3773C6.55205 8.28354 6.49937 8.15636 6.49937 8.02375L6.32 4.21313V4.21156C6.31607 4.11998 6.33071 4.02854 6.36305 3.94277C6.39539 3.85699 6.44475 3.77865 6.50817 3.71245C6.57158 3.64626 6.64774 3.59358 6.73205 3.55759C6.81636 3.5216 6.90708 3.50305 6.99875 3.50305C7.09042 3.50305 7.18114 3.5216 7.26545 3.55759C7.34976 3.59358 7.42592 3.64626 7.48933 3.71245C7.55275 3.77865 7.60211 3.85699 7.63445 3.94277C7.66679 4.02854 7.68143 4.11998 7.6775 4.21156L7.67875 4.21125Z' fill='%23FC8654'/%3E%3C/svg%3E");
}
.notification_message span.error{
  color: #FC545D;
}
.notification_message span.error:before{
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 0.5C3.41594 0.5 0.5 3.41594 0.5 7C0.5 10.5841 3.41594 13.5 7 13.5C10.5841 13.5 13.5 10.5841 13.5 7C13.5 3.41594 10.5841 0.5 7 0.5ZM9.35344 8.64656C9.40182 8.69253 9.44051 8.74772 9.46723 8.80888C9.49395 8.87003 9.50816 8.93592 9.50901 9.00265C9.50986 9.06938 9.49735 9.13561 9.47221 9.19743C9.44706 9.25925 9.4098 9.31541 9.36261 9.36261C9.31541 9.4098 9.25925 9.44706 9.19743 9.47221C9.13561 9.49735 9.06938 9.50986 9.00265 9.50901C8.93592 9.50816 8.87003 9.49395 8.80888 9.46723C8.74772 9.44051 8.69253 9.40182 8.64656 9.35344L7 7.70719L5.35344 9.35344C5.25891 9.44325 5.13303 9.49258 5.00265 9.49091C4.87227 9.48924 4.7477 9.4367 4.6555 9.3445C4.5633 9.2523 4.51076 9.12773 4.50909 8.99735C4.50742 8.86697 4.55675 8.74109 4.64656 8.64656L6.29281 7L4.64656 5.35344C4.55675 5.25891 4.50742 5.13303 4.50909 5.00265C4.51076 4.87227 4.5633 4.7477 4.6555 4.6555C4.7477 4.5633 4.87227 4.51076 5.00265 4.50909C5.13303 4.50742 5.25891 4.55675 5.35344 4.64656L7 6.29281L8.64656 4.64656C8.74109 4.55675 8.86697 4.50742 8.99735 4.50909C9.12773 4.51076 9.2523 4.5633 9.3445 4.6555C9.4367 4.7477 9.48924 4.87227 9.49091 5.00265C9.49258 5.13303 9.44325 5.25891 9.35344 5.35344L7.70719 7L9.35344 8.64656Z' fill='%23FC545D'/%3E%3C/svg%3E");
}
.notification_message span.added{
  color: #36CC82;
}
.notification_message span.added:before{
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 0.5C3.41594 0.5 0.5 3.41594 0.5 7C0.5 10.5841 3.41594 13.5 7 13.5C10.5841 13.5 13.5 10.5841 13.5 7C13.5 3.41594 10.5841 0.5 7 0.5ZM10.3828 4.82156L6.18281 9.82156C6.13674 9.87644 6.07941 9.92077 6.01471 9.95156C5.95001 9.98234 5.87945 9.99886 5.80781 10H5.79938C5.72929 9.99997 5.66 9.98522 5.59599 9.95668C5.53198 9.92815 5.47468 9.88648 5.42781 9.83438L3.62781 7.83438C3.5821 7.78589 3.54654 7.72876 3.52322 7.66633C3.4999 7.60391 3.4893 7.53745 3.49203 7.47087C3.49477 7.40429 3.51078 7.33892 3.53914 7.27862C3.56749 7.21831 3.60761 7.16429 3.65715 7.11971C3.70668 7.07514 3.76463 7.04091 3.82757 7.01905C3.89052 6.99719 3.95721 6.98813 4.02371 6.9924C4.09021 6.99668 4.15518 7.01421 4.21481 7.04396C4.27444 7.0737 4.32752 7.11507 4.37094 7.16562L5.78625 8.73812L9.61719 4.17844C9.70312 4.07909 9.8247 4.01754 9.95564 4.00711C10.0866 3.99668 10.2164 4.03819 10.317 4.12268C10.4175 4.20717 10.4808 4.32784 10.4931 4.45862C10.5055 4.5894 10.4658 4.71977 10.3828 4.82156Z' fill='%2336CC82'/%3E%3C/svg%3E");
}
.notification_message span.close{
  color: #fff;
  right: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  border-radius: 50px;
  border: 1px solid #262726;
  background: no-repeat;
  background-position-x: center;
  background-position-y: center;
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.05997 4.99984L9.02872 2.03109C9.16962 1.89044 9.24887 1.69958 9.24905 1.5005C9.24922 1.30142 9.17031 1.11042 9.02966 0.969524C8.88901 0.828627 8.69815 0.749374 8.49907 0.749198C8.29999 0.749022 8.10899 0.827939 7.9681 0.968586L4.99935 3.93734L2.0306 0.968586C1.8897 0.82769 1.69861 0.748535 1.49935 0.748535C1.30009 0.748535 1.10899 0.82769 0.968098 0.968586C0.827202 1.10948 0.748047 1.30058 0.748047 1.49984C0.748047 1.69909 0.827202 1.89019 0.968098 2.03109L3.93685 4.99984L0.968098 7.96859C0.827202 8.10948 0.748047 8.30058 0.748047 8.49984C0.748047 8.69909 0.827202 8.89019 0.968098 9.03109C1.10899 9.17198 1.30009 9.25114 1.49935 9.25114C1.69861 9.25114 1.8897 9.17198 2.0306 9.03109L4.99935 6.06234L7.9681 9.03109C8.10899 9.17198 8.30009 9.25114 8.49935 9.25114C8.69861 9.25114 8.8897 9.17198 9.0306 9.03109C9.17149 8.89019 9.25065 8.69909 9.25065 8.49984C9.25065 8.30058 9.17149 8.10948 9.0306 7.96859L6.05997 4.99984Z' fill='%23F6F6F6'/%3E%3C/svg%3E");
}
.user_wrapp{
  padding-top: 5px;
}
.user_name{
  display: flex;
  align-items: center;
  cursor: pointer;
}

.user_name svg #first_path,
.user_name svg #second_path{
  opacity: 1;
  transition: all 0.4s ease;
}
.user_name svg #fird_path{
 opacity: 0;
 transition: all 0.4s ease;
}
.user_name:hover svg #fird_path{
 opacity: 1;
}
.user_name:hover svg #first_path,
.user_name:hover svg #second_path{
  opacity: 0;
}
.balance_strip{
  position: relative;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0);
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  min-height: 107px;
  display: flex;
    justify-content: center;
    position: relative;
  z-index: 2;
  border-bottom: 1px solid #707070;
}
.balance_strip:before{
  content: '';
    background-image: url(./img/Line.svg);
    position: absolute;
    top: 0px;
    width: 100%;
    height: 1px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 4;
}
.balance_strip.inside{
  background: #414244;
}

/* radial */

.banace_item_wrap{
  display: flex;
  align-items: center;
  margin-left: 4%;
  margin-right: 4%;
  min-width: 105px;
}
.chart_label{
  margin-left: 12px;
}
.chart_label span{
  font-size: 12px;
  line-height: 21px;
  color: #F6F6F6;
  margin-left: 5px;
}


.main_title{
  padding-left: 60px;
  padding-top: 30px;
  padding-right: 60px;
  position: relative;
  z-index: 2;
}
.main_title h1{
  font-family: 'Ubuntu', sans-serif;
font-style: normal;
font-weight: 500;
font-size: 40px;
line-height: 46px;
text-transform: capitalize;
color: #FFFFFF;
margin-bottom: 20px;
}
.main_title p{
  font-weight: 400;
font-size: 20px;
line-height: 23px;
  padding-bottom: 15px;
text-transform: capitalize;
font-family: 'Ubuntu', sans-serif;

color: #C4C4C4;
}
.main_traiders_frames{
  position: relative;
  z-index: 2;
  padding-left: 60px;
  padding-right: 60px;
  max-width: 520px;
}
.traiders_frames_item{
  
  /* background-image: url(./img/BG_normal.svg);
  background-size: 100% 100%; */
  
/* Black/grey dark */

  background: #414244;
  max-width: 380px;
  width: 100%;
  margin-bottom: 14px;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.4s ease;
  padding: 1px;
}
.traiders_frames_item .bg-grad{
  background: #414244;
  width: 100%;
  height: 86px;
  padding: 13px 17px;
  display: flex;
  border-radius: 3px;
  border: 1px solid transparent;
}
.traiders_frames_item{
background: linear-gradient(50deg, rgba(196,196,196,0.46) 23%, rgba(196,196,196,1) 41%, rgba(196,196,196,0.49) 60%);
}
.traiders_frames_item:hover .bg-grad{
  background: linear-gradient(90deg, #000000 6.84%, #202724 90%)
}
.traiders_frames_item:hover{
  background: radial-gradient(97.37% 914.41% at 2.63% 12.9%, #000000 51.2%, #202724 100%);
}

.traiders_frames_item .frame_item_image img{
  width: 60px;
  height: 60px;
  border-radius: 3px;
}
.top_strip span{
font-weight: 400;
font-size: 12px;
line-height: 14px;
text-align: center;
text-transform: capitalize;
color: #414244;
display: inline-block;
background: #F6F6F6;
border-radius: 12px;
padding: 0px 13px;
}
.frame_item_content{
  margin-left: 10px;
  display: flex;
    flex-direction: column;
    justify-content: space-between;
        width: 100%;
}
.bottom_strip{
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.bottom_strip_item{
  min-width: 80px;
}
.bottom_strip_item .bs_label{
font-style: italic;
font-weight: normal;
font-size: 12px;
line-height: 20px;
color: #C4C4C4;
margin-bottom: 2px;
    display: inline-block;
}
.first_row_nav,
.bottom_strip_item .bs_value{
font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 14px;
color: #EBEBEB;
}
.bs_label_content{
  display: flex;
  align-items: flex-start;
}
.bs_triangle{
  width:12px;
  height: 12px;
  display: inline-block;
  margin-right: 4px;
  flex-shrink: 0;
}
.bs_triangle.gren{
  width: 0;
height: 0;
border-style: solid;
border-width: 0 6px 10.4px 6px;
border-color: transparent transparent #36cc81 transparent;
}
.bs_triangle.red{
  width: 0;
height: 0;
border-style: solid;
border-width: 10.4px 6px 0 6px;
border-color: #fc545c transparent transparent transparent;

}
/* Trading Screen */
.wrapScroll{
  z-index: 0;
}
*::-webkit-scrollbar {
  color: #000000;
}
.order_tabs {
  overflow-x: scroll;
}
.main_traiders_screen{
  display: flex;
  width: 100%;
  margin: 0 auto;
  flex: 1 1 auto;
}
.column_left{
  width: 75%;
}
.nav_inst_details{
  padding: 0 20px;
}
.live_trades,
.order_tabs,
.order_book{
  margin: 27px 20px;
}
.nav_inst_details::before {
  display: none;
}
.nav_inst_details::after{
  content: "";
  display: block;
  height: 1px;
  margin-top: 50px;
  border: 1px solid transparent;
  background-image: linear-gradient(270deg, rgba(196, 196, 196, 0) 4.2%, #C4C4C4 47.48%, rgba(196, 196, 196, 0) 88.73%);
}
.live_trades,
.first_row_nav,
.second_row_naw,
.right_column_naw{
  display: flex;
}
.first_row_nav{
  margin-left: 20px;
  margin-top: 25px;
  margin-bottom: 25px;
  height: 23px;
}
.first_row_nav a>span{
  border: 2px solid #707070;
  width: 27px;
  height: 14px;
  color: #fff;
  font-size: 14px;
  padding: 8px 12px;
  margin-right: 10px;
  border-radius: 50px;
}
.first_row_nav a>span:hover{
  background: #0D0D0D;
}
.second_row_naw p{
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  line-height: 1;
  color: #EBEBEB;
}
.left_column_naw img{
  max-width: 305px;
  height: 167px;
}
.right_column_naw{
  margin-left: 25px;
  flex-direction: column;
  justify-content: space-between;
}
.right_column_naw .bs_value{
  border: 1px solid #727272;
  border-radius: 16px;
  font-size: 24px;
  padding: 5px 6px;
  height: 43px;
  color: #F6F6F6;
}
.right_column_naw .bs_value::before{
  content: "";
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 10px solid #35cc81;
  display: inline-block;
  margin-bottom: 10px;
  margin-right: 5px;
}
.order_book,
.order_tabs,
.live_trades{
  background: #0D0D0D;
  border: 1px solid #414244;
  box-sizing: border-box;
  border-radius: 3px;
  padding-left: 10px;
}
.order_book{
  padding-right: 10px;
}
.order_tabs{
  padding-right: 10px;
}
.live_trades .table tr:nth-child(even),
.order_tabs .table tr:nth-child(even){
  background: #262726;
}
.title_row{
  margin: 10px 5px;
}
.order_book .title_row svg+span{
  vertical-align: super;
}
.title_row span{
  color: #f6f6f6;
  padding-left: 15px;
}
.title_row svg path{
  fill: #f6f6f6;
}
.live_trades .table{
  margin-bottom: 40px;
}
.live_trades .table thead tr th{
  padding: 11px 20px 11px 20px;
}
.live_trades .table tbody tr td{
  padding: 16px 20px 16px 20px;
}
.order_book{
  margin-top: 20px;
}
.order_book .table,
.order_book .table thead tr th{
  text-align: center;
}
.order_book .table tbody th td{
  display: flex;
}
.order_tabs .react-tabs__tab-list{
  display: flex;
  padding-left: 0px;
  padding-right: 0px;
}
.right_column_trad{
  margin-top: 70px;
  margin-left: 30px;
  margin-right: 30px;
  width: 500px;
  border-top: 1px solid #C4C4C4;
}
.right_column_trad .first {
  border-bottom: 1px solid #f6f6f6;
  margin-left: 30px;
  margin-top: 58px;
  padding: 0;
  position: relative;
  top: 12px;
  display: block;
}
td.td_gradient_bid,
td.td_gradient_ask{
  position: relative;
}
span.gradient_bid{
  position: absolute;
  top: 5px;
  border-radius: 3px;
  left: 10px;
  display: inline-block;
  height: 30px;
  background: linear-gradient(88.02deg, #3BD388 -75.75%, rgba(59, 211, 136, 0) 100%);
}
span.gradient_ask{
  position: absolute;
  top: 5px;
  border-radius: 3px;
  right: 10px;
  display: inline-block;
  height: 30px;
  background: linear-gradient(88.02deg, rgba(252, 84, 93, 0) 3.34%, #FC545D 160.97%);
}
/* Paginator */
.btn-paginator{
  margin: 0 20px;
}
.btn-paginator button{
  border: 0;
  vertical-align: middle;
  background: #0D0D0D;
}
.btn-paginator button:disabled{
  opacity: 0.5
}
.text-paginator{
  font-style: italic;
  color: #707070;
}
/* Place order */
.place_order{
  border-left: 1px;
  border-style: solid;
  padding: 40px 40px 0 46px;
  border-image-slice: 30;
  border-image-source: linear-gradient(180deg, rgba(196, 196, 196, 0) 4.2%, #C4C4C4 47.48%, rgba(196, 196, 196, 0) 88.73%);
}
.place_order h3{
  color: #C4C4C4;
  font-size: 14px;
  padding-bottom: 30px;
}
.buy_title_show h1,
.buy_title_hide h1,
.sell_title_hide h1,
.sell_title_show h1{
  color: #fff;
  font-weight: 500;
  font-size: 20px;
  padding: 8px 48px;
}
.buy_title_show,
.buy_title_hide{
  position: relative;
}
.sell_title_show h1{
  background: #FF6A6A;
  border-radius: 3px;
  z-index: 1;
}
.buy_title_show h1{
  background: #3BD388;
  border-radius: 3px;
  z-index: 1;
}
.sell_title_hide h1,
.buy_title_hide h1{
  color: #707070;
  border-radius: 3px;
  background: #414244;
  z-index: 0;
}
.sell_title_show:before{
  content: "";
  width: 29px;
  height: 29px;
  border-radius: 3px;
  background: #FF6a6a;
  top: 5px;
  right: 123px;
  box-shadow: -4px 5px 4px rgb(0 0 0 / 25%);
  transform: rotate(45deg);
  z-index: 1;
  position: absolute;
  display: inline-block;
}
.buy_title_show:after{
  content: "";
  width: 29px;
  height: 29px;
  border-radius: 3px;
  background: #3bd388;
  top: 5px;
  right: -13px;
  box-shadow: 4px -3px 4px rgb(0 0 0 / 25%);
  transform: rotate(45deg);
  z-index: 1;
  position: absolute;
  display: inline-block;
}
.place_order .wrap_tab .react-tabs__tab:before,
.place_order .wrap_tab .react-tabs__tab:before{
  display: none;
}
.place_order .react-tabs__tab-panel{
  min-height: 0;
  padding-top: 30px;
}
.place_order .tab_marker .react-tabs__tab-panel,
.place_order .tab_limit .react-tabs__tab-panel{
  padding-top: 20px;
}
.place_order .react-tabs__tab-list,
.tab_limit .react-tabs__tab-list,
.tab_marker .react-tabs__tab-list{
  padding: 0;
  margin: 0;
}
.place_order .react-tabs__tab-list .wrap_tab{
  display: flex;
  padding: 0;
  margin: 0;
}
.place_order .time_wrap{
  height: 60px;
}
.tab_limit .react-tabs__tab,
.tab_market .react-tabs__tab {
  left: -13px;
}
.wrap_field{
  display: flex;
  margin-bottom: 35px;
}
.wrap_field .TIF{
  width: 85px;
}
.wrap_field .Price,
.wrap_field .Amount{
  width: 90px;
  margin-right: 5px;
}
.wrap_field .TIF label,
.wrap_field .Price label,
.wrap_field .Amount label{
  color: #F6F6F6;
  display: inline-block;
  padding-bottom: 7px;
}
.wrap_field .Price input,
.wrap_field .Amount input{
  width: 60px;
  border: none;
  padding: 11px;
  color: #F6F6F6;
  outline: none;
  border-radius: 13px;
  background: #414244;
}
.wrap_field .drop{
  position: relative;
}
.wrap_field .drop:after{
  content: "";
  width: 12px;
  height: 6px;
  top: 6px;
  right: 0;
  color: #F6F6F6;
  position: absolute;
  display: inline-block;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='6' viewBox='0 0 12 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.96967 0.21967C1.26256 -0.0732233 1.73744 -0.0732233 2.03033 0.21967L6 4.18934L9.96967 0.21967C10.2626 -0.0732233 10.7374 -0.0732233 11.0303 0.21967C11.3232 0.512563 11.3232 0.987437 11.0303 1.28033L6.53033 5.78033C6.23744 6.07322 5.76256 6.07322 5.46967 5.78033L0.96967 1.28033C0.676777 0.987437 0.676777 0.512563 0.96967 0.21967Z' fill='%23F6F6F6'/%3E%3C/svg%3E");
}
.wrap_tab .react-tabs__tab{
  padding: 0;
}
.wrap_second_tab{
  display: flex;
}
.wrap_second_tab h3{
  padding-left: 10px;
  padding-bottom: 0;
}
.total_wrap{
  margin-top: 70px;
  margin-bottom: 20px;
}
.total_wrap span{
  color: #C4C4C4;
}
.total{
  margin-left: 8px;
}
/* Calendar style */
.place_order_calendar .react-calendar{
  width: 290px;
  border: 0;
  color: #F6F6F6;
  background: transparent;
}
.react-calendar__tile{
  padding: 12px 6px;
}
.react-calendar__navigation{
  margin-bottom: 0;
}
.react-calendar button,
.react-calendar__month-view__days__day--weekend,
.react-calendar__month-view__days__day,
.react-calendar__month-view__days__day--neighboringMonth{
  color: #F6F6F6;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__tile--now,
.react-calendar__navigation button:enabled:hover{
  background: transparent;
}
.react-calendar__tile:enabled:hover,
.place_order_calendar .react-calendar__tile--active,
.react-calendar__tile .react-calendar__month-view__days__day{
  flex: 0 0 12.2857% !important;
}
.react-calendar__tile:enabled:hover,
.place_order_calendar .react-calendar__tile--active {
  color: #0D0D0D;
  flex-basis: 14.2857% !important;
  background: #FFFFFF !important;
  border-radius: 50%;
}
.react-calendar__navigation button:disabled,
.react-calendar__tile:disabled {
  background-color: transparent !important;
  color: #717171 !important;
}
/*footer*/
footer{
  position: relative;
  z-index: 2;
}
.footer_top{
  min-height: 70px;
}
.footer_down{
  min-height: 78px;
}
.footer_top,
.footer_down{
  z-index: 2;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.footerBTN_hoverTop,
.footerBTNTop{
  background-position: 0px 15px;
}
.footerBTN,
.footerBTN_hover{
  background-position: 0px 0px;
}
.footerBTN,
.footerBTNTop{
  /*background-image: url(./img/footer_btn.svg);*/
  background-image: url(./img/footer_btn_bg_hover.png);
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: center;
  min-height: 85px;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
}
.footerBTN_hover,
.footerBTN_hoverTop{
  background-image: url(./img/footer_btn_bg.png);
  width: 100%;
  min-height: 85px;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
}
.footer_top:hover .footerBTN_hoverTop,
.footer_down:hover .footerBTN_hover{
  transition: all 0.3s linear;
  opacity: 0;
}
.footer_top a,
.footer_down a{
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 10px;
  text-align: center;
  text-transform: capitalize;
  color: #EBEBEB;
  text-decoration: none;
  position: relative;
  z-index: 2;
}
.footer_top a{
  padding-top: 5px;
}
.footer_top a img,
.footer_down a img{
  margin-top: 5px;
  margin-bottom: 4px; 
}
/*.footerBTN a::after{
  content: '';
  background: rgb(255,204,77);
background: radial-gradient(circle, rgba(255,204,77,1) 0%, rgba(65,66,68,1) 90%);
width: 100px;
height: 100px;
position: absolute;
z-index: 1;
}*/
footer:after{
  content:'';
  background-image: url(./img/Line.svg);
  position: absolute;
  top: 0px;
  width: 100%;
  height: 1px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 4;
}
.footer_bottom{
  background: #262726;
  box-shadow: 10px 0px 28px rgba(0, 0, 0, 0.25);
  min-height: 32px;
  display:flex;
  width: 100%;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}
.footer_bottom ul{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.footer_bottom ul li a{
  display: flex;
  align-items: center;
  text-decoration: none;
}
.footer_bottom ul li a.ex_link{
  font-style: italic;
font-weight: normal;
font-size: 12px;
line-height: 14px;
text-transform: capitalize;

color: #C4C4C4;
margin-right: 38px;
}
.footer_bottom ul li a.ex_link img{
  margin-left: 6px;
}

/* LOgin modal */
.login_modal{
  position: absolute;
  z-index: 2;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 457px;
  width:90%;
max-height: 614px;
height: 90vh;
left: 0;
right: 0;
margin: auto;
top: 2.5vh;
background: #262726;
/* shadow/card */
padding: 50px;
box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.24);
border-radius: 4px;
}
.modal_logo{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.modal_title{
  margin-bottom: 15px;
}
.modal_title h2{
  font-size: 24px;
line-height: 28px;
/* identical to box height */

text-align: center;
text-transform: capitalize;

/* Black/grey even lighter */

color: #F6F6F6;
}
.modal_content{
  margin-bottom: 40px;
}
.modal_content p{
  font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 18px;
text-align: center;
text-transform: capitalize;

/* Black/grey light */

color: #C4C4C4;
}
.modal_btn_wrapp{
  width: 100%;
}

/*logout*/
.logoutWrap{
  background: rgba(38, 39, 38, 1);


width: 211px;
height:56px;
border: 1px solid rgba(65, 66, 68, 1);
box-sizing: border-box;
border-radius: 3px;
position: absolute;
bottom: -60px;
    right: 0;
    padding: 12px;
    display: flex;
    align-items: center;
    transition: all 0.4s ease;
}
.logoutWrap:hover{
  background: rgba(0, 0, 0, 1);

}
.logoutWrap button{
  background-color: transparent;
  border: 0;
  font-size: 14px;
line-height: 16px;
text-align: center;
text-transform: capitalize;
color: #EBEBEB;
display: flex;align-items: center;
cursor: pointer;
}
.logoutWrap button img{
  margin-right: 8px;
}

/*Order Traders Wrap*/
.order_traders_wrap{
  flex: 1 1 auto;
}
.order_traders_tabs_wrap table{
  min-width: 1180px;
}
.order_traders_wrap .footerBTN_hoverTop,
.order_traders_wrap .footerBTNTop,
.order_traders_wrap .footer_top a img {
  transform: rotate(180deg);
}
.order_traders_tabs_wrap{
  position: relative;
  z-index: 2;
}
.orderTradesTab,
.orderTradesHistory{
  flex-grow: 1;
  display: inline-flex;
}
.orderTradesHistory{
  align-items: center;
}
.order_traders_tabs_wrap .react-tabs__tab-list{
  display: flex;
  flex-wrap: wrap;
}
/*.order_traders_wrap .footer_top{
  clip-path: polygon(100% 32%, 57% 31%, 56% 34%, 50% 93%, 48% 73%, 43% 31%, 0 30%, 0 0, 100% 0);
}*/
.react-tabs__tab-list{
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 24px;
  margin-top: 10px;
}
.react-tabs__tab{
  display: inline-flex;
    border: none;
    border-bottom: none;
    bottom: 0;
    position: relative;
    list-style: none;
    padding: 0px 14px;
    cursor: pointer;
    color: #707070;
    align-items: center;
}
.react-tabs__tab::before{
  content: '|';
  font-size: 14px;
line-height: 16px;
position: absolute;
right: -1px;
  top: 0px;
}
.react-tabs__tab:last-child:before{
  display: none;
}
.react-tabs__tab-list{
  border: none;
}
.react-tabs__tab svg{
  margin-right: 8px;
}
.react-tabs__tab:first-child svg{
    position: relative;
    top: -1px;
    margin-right: 10px;
}
.react-tabs__tab svg path{
  fill:#707070;
}
.react-tabs__tab--selected {
    background: none;
    border-color: transparent;
    color: #F6F6F6;
    border-radius: 5px 5px 0 0;
}
.react-tabs__tab--selected svg path{
  fill: #F6F6F6;
}
.react-tabs__tab:focus:after {
    content: '';
    position: absolute;
    height: 0;
    display: none;
}
.react-tabs__tab-panel {
    min-height: calc(100vh - 260px);
    padding-top: 15px;
    padding-bottom: 15px;
}
.filter_row{
  padding-left: 27px;
  padding-right: 27px;
  margin-bottom: 48px;
}
.search-wrapper.searchWrapper {
  padding: 8px 29px 8px 12px;
  background: #414244;
  border-radius: 3px !important;
border: none;
width: 100%;
height: 40px;
    box-sizing: border-box;
    color: #F6F6F6;
}
.search-wrapper.searchWrapper .chip:hover{
  cursor: pointer;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #ffffff !important;
}
.table{
  color: #C4C4C4;
  width: 100%;
}
.table thead tr th{
  font-size: 16px;
line-height: 10px;
color: #EBEBEB;
padding: 11px 20px 11px 30px;
text-align: left;
}
.table thead tr{
  border-bottom: 1px solid #C4C4C4;
}
.table tbody tr td{
font-size: 14px;
line-height: 10px;
color: #C4C4C4;
padding: 16px 20px 16px 30px;

}
.table tbody tr{
  border-bottom: 1px solid #414244;
}
.table tbody tr td .instr {
    font-weight: 400;
    font-size: 12px;
line-height: 14px;
    text-align: center;
    text-transform: capitalize;
    color: #414244;
    display: inline-block;
    background: #F6F6F6;
    border-radius: 12px;
    padding: 0px 13px;
}
.table tbody tr td .Buy{
color: #36CC82;
}
.table tbody tr td .Sell{
  color: #FC545D;
}
.table tbody tr td .colored-span{
  color: #FFCC4D;

}
.table tbody tr td svg { width:16px; height:16px; border-radius:50%; }
.table tbody tr td circle {fill:transparent; stroke-width:32;}

.table.history-table tbody tr td:nth-child(2){
  width: 165px;
}

.table.history-table tbody tr td:nth-child(3){
  width: 100px;
}
.table.history-table tbody tr td:nth-child(4){
  width: 120px;
}
.chip .custom-close {
    display: flex;
    margin-left: 4px;
    margin-right: 4px;
    position: relative;
}
.optionListContainer {
    position: absolute;
    width: 170px !important;
    background: #0D0D0D !important;
    border: 1px solid #414244;
    border-radius: 4px;
    margin-top: 1px;
    padding: 5px 0;
    z-index: 2;
    color: #fff;
    
}
.highlightOption {
  background: #0D0D0D !important;
}
.multiSelectContainer ul{
  border: none !important;
  border-radius: 0px 0px 4px 4px;
}
.highlightOption li:hover,
.multiSelectContainer li:hover {
  background: #262726 !important;
  border: 1px solid #414244 !important;
  cursor: pointer !important;
  margin: 0 5px !important;
  padding: 9px 8px !important;
  border-radius: 3px !important;
}
.multiSelectContainer li{
  font-size: 12px;
  color: #EBEBEB !important;
  padding: 10px 14px !important;
}
.searchBox {
  font-size: 14px;
line-height: 16px;
color: #F6F6F6;
}
.search-wrapper:before{
  content:'Filter by:';
  font-size: 14px;
line-height: 16px;
font-style: italic;
text-transform: capitalize;
color: #707070;
margin-right: 10px;
}
.notFound {
  color: #c4c4c4 !important;
  font-style: italic !important;
}
.left_column_trad{
  width: 40vw;
  padding-bottom: 20px;
}
.shows_paginator{
  display: inline-flex;
}
@media (max-width: 1180px) {
  .live_trades{
    flex-direction: column;
  }
  .left_column_trad,
  .right_column_trad{
    width: 100%;
  }
  /* Live trades */
  .live_trades{
    padding: 0 5px;
  }
  .live_trades .table{
    margin-bottom: 0;
  }
  /* End */

  /* Chart*/
  .right_column_trad{
    margin-top: 30px;
    margin-left: 0;
  }
  /* End */
}
@media (max-width: 1024px) {
  .mob-btn-close {
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    display: block;
    position: absolute;
  }
  .mob-btn-close button{
    border-radius: 50px;
    border: 1px solid #616261;
    background: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-image: url("data:image/svg+xml,%3Csvg width=%2710%27 height=%2710%27 viewBox=%270 0 10 10%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath d=%27M6.05997 4.99984L9.02872 2.03109C9.16962 1.89044 9.24887 1.69958 9.24905 1.5005C9.24922 1.30142 9.17031 1.11042 9.02966 0.969524C8.88901 0.828627 8.69815 0.749374 8.49907 0.749198C8.29999 0.749022 8.10899 0.827939 7.9681 0.968586L4.99935 3.93734L2.0306 0.968586C1.8897 0.82769 1.69861 0.748535 1.49935 0.748535C1.30009 0.748535 1.10899 0.82769 0.968098 0.968586C0.827202 1.10948 0.748047 1.30058 0.748047 1.49984C0.748047 1.69909 0.827202 1.89019 0.968098 2.03109L3.93685 4.99984L0.968098 7.96859C0.827202 8.10948 0.748047 8.30058 0.748047 8.49984C0.748047 8.69909 0.827202 8.89019 0.968098 9.03109C1.10899 9.17198 1.30009 9.25114 1.49935 9.25114C1.69861 9.25114 1.8897 9.17198 2.0306 9.03109L4.99935 6.06234L7.9681 9.03109C8.10899 9.17198 8.30009 9.25114 8.49935 9.25114C8.69861 9.25114 8.8897 9.17198 9.0306 9.03109C9.17149 8.89019 9.25065 8.69909 9.25065 8.49984C9.25065 8.30058 9.17149 8.10948 9.0306 7.96859L6.05997 4.99984Z%27 fill=%27%23F6F6F6%27/%3E%3C/svg%3E");
  }
  .mob_button{
    display: flex;
    justify-content: space-between;
    padding: 10px 20px 10px 20px;
  }
  .mob_buy,.mob_sell{
    color: #fff;
    width: 144px;
    height: 40px;
    border: 0px;
    border-radius: 3px;
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
  }
  .mob_buy{
    background: #3bd388;
  }
  .mob_sell{
    background: #FF6A6A;
  }
  .place_order .time_wrap{
    height: 0;
  }
  .main_traiders_screen{
    flex-direction: column;
  }
  .column_left{
    width: 100%;
  }
  .place_order{
    display: none;
    top: 0;
    width: 100vw;
    height: 120vh;
    position: absolute;
    background: #262726;
    min-width: 373px;
    min-height: 725px;
    z-index: 4;
    margin: 0 auto;
    padding: 40px 46px;
    flex-direction: column;
    align-items: center;
  }
  .place_order.show{
    display: flex;
  }
  /* Live trades */
  .live_trades .table thead tr th {
    padding: 2vh 0 2vh 4vw;
  }
  .live_trades .table tbody tr td {
    padding: 2vh 0vw 2vh 4vw;
  }
  /* End */

  /* Order book */
  span.gradient_bid{
    left: 0;
  }
  span.gradient_ask{
    right: 7px;
  }
  .order_book .table thead tr th,
  .order_book .table thead tr td{
    padding: 2vh 0;
  }
  /* End */
  .notification_wrap{
    left: 0;
    display: flex;
    z-index: 5;
    top: 0;
    width: 100vw;
    z-index: 5;
    min-width: 320px;
    justify-content: center;
  }
  .notification_message{
    left: 0;
    width: 100vw;
    background: #0D0D0D;
  }
}
@media (max-width: 768px){
  .wrapScroll{
    height: calc(100vh - 393px) !important;
  }
  .page_wrapp_tranding{
    min-height: 0;
  }
  .first_row_nav{
    justify-content: space-between;
  }
  .second_row_naw{
    padding-top: 10px;
  }
  .paginator_wrapp{
    display: flex;
    padding-bottom: 5px;
    align-items: center;
    flex-direction: column-reverse;
  }
  .btn-paginator{
    background-color: #414244;
    border-radius: 50px;
    border-color: #414244;
    padding: 5px;
  }
  .btn-paginator button{
    height: 16px;
    background-color: #414244;
  }
  .nav_inst_details::after {
    display: none;
  }
  .nav_inst_details::before {
    content: "";
    display: block;
    height: 1px;
    margin-top: 0px;
    border: 1px solid transparent;
    background-image: linear-gradient(270deg, rgba(196, 196, 196, 0) 4.2%, #C4C4C4 47.48%, rgba(196, 196, 196, 0) 88.73%);
  }
}
@media (max-width: 645px) {
  .wrapBalance,
  .wrapBalanceTrade{
    border-top: 2px solid #aeaeae;
    border-bottom: 2px solid #707070;
  }
  .wrapBalanceTrade{
    background: #414244;
  }
  .balance_strip:before{
    display: none;
  }
  .footerBTNTop,
  .footerBTN_hoverTop,
  .footerBTN,
  .footerBTN_hover{
    background-size: contain;
  }
  .main_traiders_frames, .main_title{
    padding-right: 3vw;
    padding-left: 3vw;
  }
  .wrap_main{
    height: calc(100vh - 140px);
    min-height: 400px;
  }
  .balance_strip{
    justify-content: flex-start;
  }
  .footer_down {
    min-height: 7vh;
  }
}
@media( max-width: 480px){
  .react-tabs__tab{
    padding: 0 6px;
  }
  .react-tabs__tab span{
    font-size: 12px;
  }
}
@media (max-width: 430px) {
  .footer_top a{
    padding-top: 23px;
  }
  .footer_down a img {
    margin-bottom: 0px;
    margin-top: 0px;
  }
  .footer_bottom{
    padding-top: 10px;
    padding-bottom: 10px
  }
}